import styled from "@emotion/styled";

import { Button } from "scmp-app/components/posties/button";

export const StyledButton = styled(Button)`
  margin-block-end: 0;
  padding-block: 8px;
  padding-inline: 12px;

  font-size: 12px;
  line-height: 9px;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block: 8px;
    padding-inline: 16px;

    font-size: 14px;
    line-height: 18px;
  }
`;
