import { useChain, useSpring, useSpringRef } from "@react-spring/web";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";

import { hamburgerMenuAtom } from "./atoms";

export const useHamburgerMenuAnimation = () => {
  const state = useAtomValue(hamburgerMenuAtom);
  const [longestEdgeUnit, setLongestEdgeUnit] = useState("vh");

  const showWrapperReference = useSpringRef();
  const openMenuReference = useSpringRef();
  const showItemsReference = useSpringRef();

  const getEdgeLengthValue = useCallback(
    (value: number) => `${value.toString()}${longestEdgeUnit}`,
    [longestEdgeUnit],
  );

  const wrapperStyles = useSpring({
    from: {
      display: "none",
    },
    ref: showWrapperReference,
    to: {
      display: state.isOpen ? "block" : "none",
    },
  });

  const initialSize = getEdgeLengthValue(0);
  const finalSize = getEdgeLengthValue(400);
  const finalSizeOffset = getEdgeLengthValue(-200);

  const backgroundStyles = useSpring({
    from: {
      backgroundColor: "#ffffff",
      blockSize: initialSize,
      inlineSize: initialSize,
      insetBlockStart: initialSize,
      insetInlineEnd: initialSize,
    },
    ref: openMenuReference,
    to: {
      backgroundColor: "#1b4191",
      blockSize: state.isOpen ? finalSize : initialSize,
      inlineSize: state.isOpen ? finalSize : initialSize,
      insetBlockStart: state.isOpen ? finalSizeOffset : initialSize,
      insetInlineEnd: state.isOpen ? finalSizeOffset : initialSize,
    },
  });

  const contentWrapperStyles = useSpring({
    from: {
      opacity: 0,
    },
    ref: showItemsReference,
    to: {
      opacity: state.isOpen ? 1 : 0,
    },
  });

  useChain(
    state.isOpen
      ? [showWrapperReference, openMenuReference, showItemsReference]
      : [showItemsReference, openMenuReference, showWrapperReference],
    [0, 0.1, 0.6],
  );

  useEffect(() => {
    const callback = () => {
      setLongestEdgeUnit(window.outerHeight > window.outerWidth ? "vh" : "vw");
    };
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []);

  return { backgroundStyles, contentWrapperStyles, wrapperStyles };
};
