import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";

import { HeaderActions } from "./header-actions";
import LogoLearn from "./img-logo-learn.svg";
import LogoPosties from "./img-logo-posties.svg";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  block-size: 66px;
  border-block-end: 2px solid #d9d9d9;

  ${props => props.theme.breakpoints.up("tablet")} {
    block-size: 100px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  inline-size: 100%;
  max-inline-size: 1560px;
`;

export const StyledHeaderActions = styled(HeaderActions)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const StyledLogoPosties = styled(LogoPosties)``;

export const StyledLogoLearn = styled(LogoLearn)``;

export const LogoLink = styled(BaseLink)``;

export const LogoContainer = styled.div`
  flex: 1;
  ${StyledLogoPosties} {
    display: inline-block;

    inline-size: 155px;
    block-size: auto;

    ${theme.breakpoints.up("tablet")} {
      inline-size: 220px;
    }

    ${theme.breakpoints.up("mediumDesktop")} {
      inline-size: 280px;
    }
  }

  ${StyledLogoLearn} {
    display: inline-block;

    inline-size: 96px;
    block-size: auto;

    ${theme.breakpoints.up("tablet")} {
      inline-size: 144px;
    }
  }
`;
