import { PageType } from "@product/scmp-sdk";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import { pathname as postiesEventsPathname } from "scmp-app/pages/posties/events";
import { pathname as postiesKidsPathname } from "scmp-app/pages/posties/kids";
import { pathname as postiesVideoPathname } from "scmp-app/pages/posties/video";
import type { hooksHeaderMenuDisplayQuery } from "scmp-app/queries/__generated__/hooksHeaderMenuDisplayQuery.graphql";

export const useMenuData = () => {
  const environment = useRelayEnvironment();

  const [{ result: data }, { execute }] = useAsync(() =>
    fetchQuery<hooksHeaderMenuDisplayQuery>(
      environment,
      graphql`
        query hooksHeaderMenuDisplayQuery {
          queue(filter: { name: "posties_menu_read" }) {
            items(first: 14) {
              edges {
                node {
                  ... on Section {
                    urlAlias
                    name
                  }
                }
              }
            }
          }
        }
      `,
      {},
    ).toPromise(),
  );

  useMountEffect(() => {
    void execute();
  });

  const menuData = useMemo(
    () => [
      {
        name: "Read",
        query: {
          pageType: PageType.PostiesKids,
        },
        subMenuLinkProps:
          data?.queue?.items?.edges.map(item => ({
            name: item.node.name ?? "",
            query: {
              pageType: PageType.PostiesSection,
            },
            urlAlias: item.node.urlAlias ?? "",
          })) ?? [],
        urlAlias: postiesKidsPathname,
      },
      {
        name: "Video",
        query: {
          pageType: PageType.PostiesVideo,
        },
        urlAlias: postiesVideoPathname,
      },
      {
        name: "ePaper",
        query: {},
        urlAlias: "/epaper/posties",
      },
      {
        name: "Events",
        query: {
          pageType: PageType.PostiesEvents,
        },
        urlAlias: postiesEventsPathname,
      },
    ],
    [data?.queue?.items?.edges],
  );

  return { menuData };
};
