import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { StyledOffsetContainer } from "scmp-app/components/posties/button/styles";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";
import { setResponsiveVariantStyles } from "scmp-app/lib/styles/responsive";

export const Container = styled.div``;

export const FeaturesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type ActionButtonProps = {
  $extraCss?: SerializedStyles;
  $responsiveVariants: ResponsiveVariants<"hidden" | "show">;
};

export const ActionButton = styled.div<ActionButtonProps>`
  cursor: pointer;

  ${props =>
    setResponsiveVariantStyles(props.$responsiveVariants, variant => {
      switch (variant) {
        case "hidden":
          return css`
            display: none;
          `;
        case "show":
          return css`
            display: block;

            margin-inline-start: 8px;

            ${props.theme.breakpoints.up("tablet")} {
              margin-inline-start: 16px;
            }

            ${props.$extraCss}
          `;
      }
    })}
`;

export const RedirectButtonContainer = styled.div`
  ${StyledOffsetContainer} {
    &::before {
      ${props => props.theme.breakpoints.up("tablet")} {
        inset-block-start: 4px;
        inset-inline-start: -3px;
      }
    }
  }
`;
